<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.viceDeanResearch.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.viceDeanResearch.list.title"></app-i18n>
      </h1>

      <app-vice-dean-research-list-toolbar></app-vice-dean-research-list-toolbar>
      <app-vice-dean-research-list-filter></app-vice-dean-research-list-filter>
      <app-vice-dean-research-list-table></app-vice-dean-research-list-table>
    </div>
  </div>
</template>

<script>
import ViceDeanResearchListFilter from '@/modules/vice-dean-research/components/vice-dean-research-list-filter.vue';
import ViceDeanResearchListTable from '@/modules/vice-dean-research/components/vice-dean-research-list-table.vue';
import ViceDeanResearchListToolbar from '@/modules/vice-dean-research/components/vice-dean-research-list-toolbar.vue';

export default {
  name: 'app-vice-dean-research-list-page',

  components: {
    [ViceDeanResearchListFilter.name]: ViceDeanResearchListFilter,
    [ViceDeanResearchListTable.name]: ViceDeanResearchListTable,
    [ViceDeanResearchListToolbar.name]: ViceDeanResearchListToolbar,
  },
};
</script>

<style>
</style>
